import { render, staticRenderFns } from "./wmsPurchaseOrderAdd.vue?vue&type=template&id=610480f8&scoped=true&"
import script from "./wmsPurchaseOrderAdd.vue?vue&type=script&lang=js&"
export * from "./wmsPurchaseOrderAdd.vue?vue&type=script&lang=js&"
import style0 from "./wmsPurchaseOrderAdd.vue?vue&type=style&index=0&id=610480f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610480f8",
  null
  
)

export default component.exports