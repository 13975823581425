import request from '@/api/request.js'

/**
 * @Description: 采购单
 */

// 分页查询
export function pageWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/batchDelete',
        method: 'POST',
        data
    })
}

// 供应商已发货
export function alreadySendWmsPurchaseOrder(data) {
    return request({
        url: '/api/wmsPurchaseOrder/alreadySend',
        method: 'POST',
        data
    })
}

